/**
 * Get List
 */
export const CONTACT_ENQUIRY_LIST = "CONTACT_ENQUIRY_LIST";
export const CONTACT_ENQUIRY_LIST_SUCCESS = "CONTACT_ENQUIRY_LIST_SUCCESS";
export const CONTACT_ENQUIRY_LIST_FAILURE = "CONTACT_ENQUIRY_LIST_FAILURE";

/**
 * DELETE BY ID
 */
export const CONTACT_ENQUIRY_DELETE = "CONTACT_ENQUIRY_DELETE";
export const CONTACT_ENQUIRY_DELETE_SUCCESS = "CONTACT_ENQUIRY_DELETE_SUCCESS";
export const CONTACT_ENQUIRY_DELETE_FAILURE = "CONTACT_ENQUIRY_DELETE_FAILURE";