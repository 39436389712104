/**
 * Get List
 */
export const JOURNAL_LIST = "web/JOURNAL_LIST";
export const JOURNAL_LIST_SUCCESS = "web/JOURNAL_LIST_SUCCESS";
export const JOURNAL_LIST_FAILURE = "web/JOURNAL_LIST_FAILURE";

//Get detail by slug
export const JOURNAL_BY_SLUG = "JOURNAL_BY_SLUG";
export const JOURNAL_BY_SLUG_SUCCESS = "JOURNAL_BY_SLUG_SUCCESS";
export const JOURNAL_BY_SLUG_FAILURE = "JOURNAL_BY_SLUG_FAILURE";

//featured journal
export const JOURNAL_FEATURED_LIST = "JOURNAL_FEATURED_LIST";
export const JOURNAL_FEATURED_LIST_SUCCESS = "JOURNAL_FEATURED_LIST_SUCCESS";
export const JOURNAL_FEATURED_LIST_FAILURE = "JOURNAL_FEATURED_LIST_FAILURE";

//Get journal page detail by slug
export const JOURNAL_PAGE_DETAIL = "JOURNAL_PAGE_DETAIL";
export const JOURNAL_PAGE_DETAIL_SUCCESS = "JOURNAL_PAGE_DETAIL_SUCCESS";
export const JOURNAL_PAGE_DETAIL_FAILURE = "JOURNAL_PAGE_DETAIL_FAILURE";

//Get journal board list
export const JOURNAL_EDITORIAL_BOARD = "JOURNAL_EDITORIAL_BOARD";
export const JOURNAL_EDITORIAL_BOARD_SUCCESS = "JOURNAL_EDITORIAL_BOARD_SUCCESS";
export const JOURNAL_EDITORIAL_BOARD_FAILURE = "JOURNAL_EDITORIAL_BOARD_FAILURE";


//Get reviewer board list
export const JOURNAL_REVIEWER_BOARD = "JOURNAL_REVIEWER_BOARD";
export const JOURNAL_REVIEWER_BOARD_SUCCESS = "JOURNAL_REVIEWER_BOARD_SUCCESS";
export const JOURNAL_REVIEWER_BOARD_FAILURE = "JOURNAL_REVIEWER_BOARD_FAILURE";

//Get recent EDITORIAL board list
export const RECENT_EDITORIAL_BOARD = "RECENT_EDITORIAL_BOARD";
export const RECENT_EDITORIAL_BOARD_SUCCESS = "RECENT_EDITORIAL_BOARD_SUCCESS";
export const RECENT_EDITORIAL_BOARD_FAILURE = "RECENT_EDITORIAL_BOARD_FAILURE";

//Journal volumne list
export const JOURNAL_VOLUME_LIST = "JOURNAL_VOLUME_LIST";
export const JOURNAL_VOLUME_LIST_SUCCESS = "JOURNAL_VOLUME_LIST_SUCCESS";
export const JOURNAL_VOLUME_LIST_FAILURE = "JOURNAL_VOLUME_LIST_FAILURE";

//Journal volumne issue list
export const JOURNAL_VOLUME_ISSUE_ARTICLES = "JOURNAL_VOLUME_ISSUE_ARTICLES";
export const JOURNAL_VOLUME_ISSUE_ARTICLES_SUCCESS = "JOURNAL_VOLUME_ISSUE_ARTICLES_SUCCESS";
export const JOURNAL_VOLUME_ISSUE_ARTICLES_FAILURE = "JOURNAL_VOLUME_ISSUE_ARTICLES_FAILURE";

//Editorial Board Category
export const EDITOIRAL_BOARD_CATEGORY_LIST = "EDITOIRAL_BOARD_CATEGORY_LIST";
export const EDITOIRAL_BOARD_CATEGORY_LIST_SUCCESS = "EDITOIRAL_BOARD_CATEGORY_LIST_SUCCESS";
export const EDITOIRAL_BOARD_CATEGORY_LIST_FAILURE = "EDITOIRAL_BOARD_CATEGORY_LIST_FAILURE";
